import { takeEvery, put, call, all } from "redux-saga/effects";
import { getFirstMessage } from "../../Helpers";
import {
  loading,
  alert,
  history,
  services,
  watchApiResponse,
  _,
} from "../../Utils";
import * as c from "./constant";

function* checkAuthentication() {
  const token = sessionStorage.getItem("token");
  if (token) {
    yield put({
      type: "AUTHENTICATE",
      isSuccess: true,
    });
    yield myProfile();
    return;
  }
  yield put({
    type: "AUTHENTICATE",
    isSuccess: false,
  });
  // history.push('/jobseeker')
}

function* login({ args }) {
  yield put(loading("LOGIN"));

  const response = yield call(services.post(`api/web/authenticate`), args);

  yield put(loading(null));

  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    sessionStorage.setItem("token", data.token);
    alert.success("Login successfully!");
    yield put({
      type: "AUTHENTICATE",
      isSuccess: true,
    });
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
    history.push("/applicant/profile");
    yield myProfile();
  });
}

function* myProfile() {
  const response = yield call(services.get(`api/service/my`));

  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    const newData = _.forEach(data, function (value, key) {
      if (_.isNil(value)) return "";
    });

    yield put({
      type: "PROFILE",
      data: newData,
    });
    const jsondata = JSON.stringify(data);
    sessionStorage.setItem("profile", jsondata);
  });
}

function* logout() {
  yield put({
    type: "RESET_AUTH",
  });

  yield put(loading("SIGN_OUT"));

  sessionStorage.clear();
  alert.warning("You have logged out");

  yield put(loading(null));

  yield put({
    type: "MODAL",
    data: {
      isOpen: false,
    },
  });

  yield put({
    type: "AUTHENTICATE",
    isSuccess: false,
  });

  history.push({ pathname: "/" });
}

function* forgotPassword({ args }) {
  yield put(loading("FORGOT_PASSWORD"));
  const response = yield call(services.post(`api/forgot`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
  });

  history.push({ pathname: "/reset-password" });
}

function* verifyResetCode({ code, cb }) {
  yield put(loading("VERIFY_RESET_CODE"));

  yield put(loading(null));

  const isExpired = false;

  cb(isExpired);
}

function* resetPassword({ args }) {
  yield put(loading("RESET_PASSWORD"));

  yield put(loading(null));

  alert.warning(`Password has been reset.`);

  history.push({ pathname: "/" });
}

function* register({ args }) {
  yield put(loading("APPLICANT_REGISTER"));

  const response = yield call(services.post(`api/service/register`), args);

  yield put(loading(null));

  yield call(watchApiResponse, response, function* () {
    const { message = "", errors = {} } = response.data;
    if (message === "") return alert.warning(getFirstMessage(errors));
    alert.success(message);
    history.push("/jobseeker");
  });
}

function* country() {
  const response = yield call(services.get(`api/service/country`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response;
    yield put({
      type: c.GOT_COUNTRY,
      data,
    });
  });
}

function* jobCategory() {
  const response = yield call(services.get(`service/jobcategory`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_CATEGORY,
      data,
    });
  });
}

function* getClient() {
  const response = yield call(services.get(`api/service/client`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response;
    yield put({
      type: c.GOT_CLIENT,
      data,
    });
  });
}

function* getJobs({ args, jobs = [] }) {
  const response = yield call(services.get(`service/jobs`), args);
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    if (typeof data === "object" && data.length === undefined) {
      let apidata = data.data || [];
      let newData = [...jobs, ...apidata];
      return yield put({
        type: c.GOT_JOBS,
        data: newData,
        meta: {
          from: data.current_page,
        },
      });
    }
    // yield put({
    //     type: c.CLEAR_LIST
    // })
  });
}

function* news() {
  const response = yield call(services.get(`api/news`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_NEWS,
      data: data,
    });
  });
}

function* advisories() {
  const response = yield call(services.get(`api/news`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_ADVISORIES,
      data: data,
    });
  });
}

function* interviews() {
  const response = yield call(services.get(`api/interview`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_INTERVIEW,
      data: data,
    });
  });
}

function* newsDetails({ id }) {
  const response = yield call(services.get(`api/news/${id}`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_NEWS_DETAILS,
      data: data,
    });
  });
}

function* jobDetails({ id }) {
  const response = yield call(services.get(`service/jobs/${id}`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_JOB_DETAILS,
      data: data,
    });
  });
}

function* apply({ id }) {
  const response = yield call(services.put(`api/jobs/${id}/apply`));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield jobDetails({ id });
  });
}

function* save({ id }) {
  const response = yield call(services.put(`api/jobs/${id}/save`));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield jobDetails({ id });
  });
}

function* faq() {
  const response = yield call(services.get(`api/faq`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_FAQ,
      data,
    });
  });
}

function* testimonial() {
  const response = yield call(services.get(`api/testimonial`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_TESTIMONIAL,
      data,
    });
  });
}

function* location() {
  const response = yield call(services.get(`service/location`));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;

    yield put({
      type: c.GOT_LOCATION,
      data,
    });
  });
}

function* forgot({ args }) {
  const response = yield call(services.post(`api/forgot`), args);
  yield call(watchApiResponse, response, function* () {
    // const { message } = response.data;
    alert.success(
      "A message was sent to your email on how to change your password."
    );
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
  });
}

function* recover({ id }) {
  yield put(loading("RECOVER"));
  const response = yield call(services.get(`api/forgot/${id}`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    // const { message } = response.data;
    yield put({
      type: c.GOT_RECOVER,
      data: {
        valid: true,
      },
    });
  });
}

function* links() {
  yield put(loading("RECOVER"));
  const response = yield call(services.get(`api/links`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const data = response.data;
    yield put({
      type: c.GOT_LINKS,
      data,
    });
  });
}

function* changePassword({ args, id }) {
  yield put(loading("RECOVER"));
  const response = yield call(services.post(`api/forgot/${id}`), args);
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;

    alert.success(message);
    yield put({
      type: c.GOT_RECOVER,
      data: {
        valid: false,
      },
    });
    history.push("/");
  });
}

function* contact({ args }) {
  yield put(loading("INQUIRY"));
  const response = yield call(services.post(`web/message/inquiry`), args);
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
  });
  yield put(loading(null));
}

function* contactRecruitment({ args }) {
  yield put(loading("CONTACT_RECRUITMENT"));
  const response = yield call(services.post(`web/message/reg_error`), args);
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;
    alert.success(message);
    yield put({
      type: "MODAL",
      data: {
        isOpen: false,
      },
    });
  });
  yield put(loading(null));
}

function* removeAccount({ args }) {
  yield put(loading("RECOVER"));
  const response = yield call(services.post(`api/remove_account`), args);
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;

    alert.success(message);
    yield put({
      type: c.GOT_REMOVE_ACCOUNT_CONFIRM,
      data: {
        valid: false,
      },
    });
  });
}

function* removeAccountConfirm({ id }) {
  yield put(loading("RECOVER"));
  const response = yield call(services.get(`api/remove_account/${id}`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { message } = response.data;

    alert.success(message);
    yield put({
      type: c.GOT_REMOVE_ACCOUNT_CONFIRM,
      data: {
        valid: true,
      },
    });
  });
}

function* getClientLogos() {
  yield put(loading("GET_CLIENT_LOGOS"));
  const response = yield call(services.get(`api/client/logo`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { data } = response;
    yield put({
      type: c.GOT_CLIENT_LOGOS,
      data,
    });
  });
}

function* getClientTestimonials() {
  yield put(loading("GET_CLIENT_TESTIMONIAL"));
  const response = yield call(services.get(`web/testimonial_clients`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_CLIENT_TESTIMONIAL,
      data,
    });
  });
}

function* checkUser({ args, onSuccess }) {
  yield put(loading("GET_CHECK_USER"));
  const response = yield call(services.post(`/api/web/checkuser`), args);
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    if (data === "exist") {
      onSuccess();
    }
  });
}

function* getApplicantSources() {
  yield put(loading("GET_APPLICANT_SOURCE"));
  const response = yield call(services.get(`/api/applicantsource`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_APPLICANT_SOURCE,
      data,
    });
  });
}

function* getWebContent() {
  yield put(loading("GET_WEB_CONTENT"));
  const response = yield call(services.get(`/api/webcontent`));
  yield put(loading(null));
  yield call(watchApiResponse, response, function* () {
    const { data } = response.data;
    yield put({
      type: c.GOT_WEB_CONTENT,
      data,
    });
  });
}

export default function* authSaga() {
  yield all([
    takeEvery("CHECK_AUTH", checkAuthentication),
    takeEvery(c.LOGIN, login),
    takeEvery("SIGN_OUT", logout),
    takeEvery("FORGOT_PASSWORD", forgotPassword),
    takeEvery("VERIFY_RESET_CODE", verifyResetCode),
    takeEvery("RESET_PASSWORD", resetPassword),
    takeEvery(c.CHANGE_PASSWORD, changePassword),
    takeEvery(c.REGISTER, register),
    takeEvery("MY_PROFILE", myProfile),
    takeEvery(c.GET_COUNTRY, country),
    takeEvery(c.GET_CATEGORY, jobCategory),
    takeEvery(c.GET_CLIENT, getClient),
    takeEvery(c.GET_JOBS, getJobs),
    takeEvery(c.GET_JOB_DETAILS, jobDetails),
    takeEvery(c.GET_NEWS, news),
    takeEvery(c.GET_NEWS_DETAILS, newsDetails),
    takeEvery(c.GET_ADVISORIES, advisories),
    takeEvery(c.GET_INTERVIEW, interviews),
    takeEvery(c.APPLY, apply),
    takeEvery(c.SAVE, save),
    takeEvery(c.GET_FAQ, faq),
    takeEvery(c.GET_TESTIMONIAL, testimonial),
    takeEvery(c.GET_LOCATION, location),
    takeEvery(c.FORGOT, forgot),
    takeEvery(c.CONTACT, contact),
    takeEvery(c.CONTACT_RECRUITMENT, contactRecruitment),
    takeEvery(c.GET_RECOVER, recover),
    takeEvery(c.GET_LINKS, links),
    takeEvery(c.GET_REMOVE_ACCOUNT, removeAccount),
    takeEvery(c.GET_REMOVE_ACCOUNT_CONFIRM, removeAccountConfirm),
    takeEvery(c.GET_CLIENT_LOGOS, getClientLogos),
    takeEvery(c.GET_CLIENT_TESTIMONIAL, getClientTestimonials),
    takeEvery(c.GET_CHECK_USER, checkUser),
    takeEvery(c.GET_APPLICANT_SOURCE, getApplicantSources),
    takeEvery(c.GET_WEB_CONTENT, getWebContent),
  ]);
}
